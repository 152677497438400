<template>
    <div class="row justify-content-center" v-if="loading">
        <div class="col-sm-5 text-center pt-2">
            <font-awesome-icon icon="spinner" spin size="3x"></font-awesome-icon>
            <p class="text-center my-1">Loading...</p>
        </div>
    </div>
    <div v-else>
        <div v-if="!access">
            <NotFound />
        </div>
        <div v-else>
            <div class="pb-2 mb-3 border-bottom">
                <h2>Leave Encash</h2>
            </div>
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">Dashboard</router-link>
                    </li>
                    <li class="breadcrumb-item">
                        <router-link to="my-leaves">My Leaves</router-link>
                    </li>
                    <li class="breadcrumb-item active" aria-current="page">Leave Encash</li>
                </ol>
            </nav>
            <b-container fluid>
                <div v-if="api_error">
                    <b-alert
                        :show="alertDismissCountDown"
                        dismissible
                        variant="danger"
                        @dismissed="alertDismissCountDown=0"
                        @dismiss-count-down="alertCountDownChanged"
                    >{{api_error}}</b-alert>
                </div>
                <b-row>
                    <b-col lg="6" class="mb-1rem">
                        <router-link
                            :to="{name: 'apply-leave-encash'}"
                            class="btn btn-primary text-right"
                            tag="button"
                        >
                            <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Apply Leave Encash
                        </router-link>
                    </b-col>
                </b-row>

                <b-table
                    show-empty
                    striped
                    hover
                    :items="items"
                    :fields="fields"
                    no-local-sorting
                    @sort-changed="customSorting"
                    responsive
                >
                    <template v-slot:cell(status)="row">
                        <span v-if="row.item.status == 'APPLIED'"><b-badge variant="info">{{row.item.status}}</b-badge></span>
                        <span v-else-if="row.item.status == 'APPROVED'"><b-badge variant="success">{{row.item.status}}</b-badge></span>
                        <span v-else-if="row.item.status == 'REJECTED'"><b-badge variant="danger">{{row.item.status}}</b-badge></span>
                        <span v-else><b-badge variant="dark">{{row.item.status}}</b-badge></span>
                    </template>
                    <template v-slot:cell(created_at)="row">
                        {{ convertToDate(row.item.created_at) }}
                    </template>
                    <template v-slot:cell(reason)="row">
                        <p class="text-truncate" style="width:70px" v-b-tooltip.hover :title="row.item.reason">
                            {{row.item.reason}}
                        </p>
                    </template>
                </b-table>

                <b-row align-h="between" v-if="totalRows">
                    <b-col lg="4" class="p-3">
                        <div class="row input-group">
                            <div class="input-group-append my-2">Showing</div>
                            <div class="mx-1em">
                                <select
                                    class="form-control"
                                    v-model="urlBody.per_page"
                                    @change="changePageCount"
                                >
                                    <option
                                        v-for="option in pageOptions"
                                        :key="option.index"
                                    >{{option}}</option>
                                </select>
                            </div>
                            <div class="input-group-append my-2" v-if="totalRows>urlBody.per_page">of {{totalRows}}</div>
                        </div>
                    </b-col>
                    <b-col lg="4" class="p-3">
                        <nav aria-label="Page navigation" class="justify-content-center">
                            <ul class="pagination float-right">
                                <li class="page-item">
                                    <span
                                        class="page-link"
                                        v-on:click="pagination(urlBody.page - 1)"
                                        v-if="urlBody.page > 1"
                                        style="cursor:pointer"
                                    >
                                        <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                    </span>
                                    <span
                                        class="page-link text-muted"
                                        style="cursor:not-allowed !important;"
                                        v-else
                                    >
                                        <font-awesome-icon class="mr-2" icon="angle-double-left"></font-awesome-icon>Previous
                                    </span>
                                </li>
                                <li class="page-item">
                                    <span
                                        class="page-link text-muted"
                                        style="cursor:not-allowed !important;"
                                        v-if="urlBody.page == lastPage"
                                    >
                                        Next
                                        <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                    </span>
                                    <span
                                        class="page-link"
                                        v-else
                                        v-on:click="pagination(urlBody.page +1)"
                                        style="cursor:pointer"
                                    >
                                        Next
                                        <font-awesome-icon class="mr-2" icon="angle-double-right"></font-awesome-icon>
                                    </span>
                                </li>
                            </ul>
                        </nav>
                    </b-col>
                </b-row>
            </b-container>

        
        </div>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "created_at",
                order: "desc",
                page: 1,
                per_page: 10
            },
            api_error: "",
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "value", label: "Number of Days Encashed", sortable: true},
                { key: "status",sortable: true},
                { key: "created_at" ,label: "Applied At",sortable: true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                }
            ],
            items: null,
            totalRows: 1,
            lastPage: 1,
            pageOptions: [10, 25, 50, 100],
            access: true,
            enableLeaveEncash: false,
            loading: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            message:"",
            start_date:'',
            end_date:'',
            months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        };
    },
    mounted() {
        const leaves_encash_months = this.$store.getters.getAppSetting("payroll", "leave_encash_month");
        const current_date = new Date();
        const current_month = this.months[current_date.getMonth()];
        this.enableLeaveEncash = leaves_encash_months.includes(current_month);
        this.getEmployee();
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        convertToDate(D){
            var date = new Date(D);
            return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-'+ date.getFullYear();
        },

        getDefaultFilters() {
            let api_params = {
                page: this.urlBody.page,
                per_page: this.urlBody.per_page,
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
            };
            return api_params;
        },

        getEmployee: function() {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            this.axios.get(this.$api.get_employees, query).then(response => {
                let encash_leaves = response.data.data.leave_group.leaves_encashed ? response.data.data.leave_group.leaves_encashed : 0;
                if(!this.enableLeaveEncash || encash_leaves == 0) {
                    this.access = false;
                }
                this.getLeavesEncashes();
                this.loading = false;
                this.updateProgressBar(true);
            })
            .catch(err => {
                this.loading = false;
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
        
        getLeavesEncashes: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_leaves_encashes, query).then(response => {
                const result = response.data.data.leaves_encash;
                this.items = result.data;
                this.urlBody.page = result.current_page;
                this.lastPage = result.last_page;
                this.urlBody.per_page = result.per_page;
                this.totalRows = result.total;
                this.api_error = "";
                this.updateProgressBar(true);
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getLeaves(urlBody);
        },

        pagination: function(pageNumber) {
            var newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.page = pageNumber;
            this.api_error = "";
            this.getRemainingLeaves();
            this.getLeaves(newQuery);
        },

        changePageCount: function() {
            let newQuery = this.deleteEmptyKeys(this.$route.query);
            newQuery.per_page = this.urlBody.per_page;
            this.api_error = "";
            this.getLeaves(newQuery);
        },
        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>

<style >
.reasonClass {
    width:15% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1px;

}
.btn-outline-light-black {
    border: 1px solid black;
}
</style>